/**
 *
 * These styles are solely for adding [background images] or
 * [background colors] to blocks.
 *
 */

export default {

    heroContainer: {
        position: `relative`,
        mt: [6, 6],
        pt: [6, 6],
        '::before, ::after': {
            position: `absolute`,
            content: `" "`,
            width: `full`,
            height: `80%`,
            top: '-20px',
            right: 0
        },
        '::before': {
            zIndex: -3,
            bg: `white`,
            background: t => `linear-gradient(
        145deg,
        ${t.colors.alpha} 0%,
        ${t.colors.beta} 100%
      )`
        }
    },
    servicesContainer: {
        px: [3, 4]
    }
}
