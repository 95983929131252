import React from 'react'
import {graphql} from 'gatsby'
import {Container} from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import ModalSimple from '@solid-ui-blocks/Modal/Block02'
import Team from '@solid-ui-blocks/Testimonials/Block02'
import Header from '@solid-ui-blocks/Header/Block01'
import Features from '@solid-ui-blocks/Features/Block05'
import OurValues from '@solid-ui-blocks/Features/Block04'
import Footer from '@solid-ui-blocks/Footer/Block01'
import {normalizeBlockContentNodes} from '@blocks-helpers'
import styles from './_styles'


const About = props => {
    const {allBlockContent} = props.data
    const content = normalizeBlockContentNodes(allBlockContent?.nodes)

    return (
        <Layout {...props}>
            <Seo title='Über Uns'/>
            {/* Modals */}
            <ModalWithTabs content={content['authentication']} reverse/>
            <ModalWithTabs content={content['contact']}/>
            <ModalSimple content={content['advertisement']}/>
            {/* Blocks */}
            <Header content={content['header']}/>
            <Container variant='full' sx={styles.heroContainer}>
                <Features content={content['features']}/>
            </Container>
            <Divider space='5'/>
            <OurValues content={content['our-values']}></OurValues>
            <Divider space='5'/>
            <Container variant='full'>
                <Team content={content['team']}></Team>
            </Container>
            <Divider space='5'/>
            <Divider space='5'/>
            <Footer content={content['footer']}/>
        </Layout>
    )
}

export const query = graphql`
  query innerpageSiteAboutBlockContent {
    allBlockContent(
      filter: { page: { in: ["site/about", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default About
