import React from 'react'
import {Box, Container, Flex} from 'theme-ui'
import Reveal from '@solid-ui-components/Reveal'
import Divider from '@solid-ui-components/Divider'
import ContentText from '@solid-ui-components/ContentText'
import ContentImages from '@solid-ui-components/ContentImages'
import ContentContainer from '@solid-ui-components/ContentContainer'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import ContentButtons from "@elegantstack/solid-ui-components/src/ContentButtons";

const styles = {
    avatar: {
        width: 150,
        bg: `omegaLighter`,
        borderTopColor: `omegaLighter`,
        borderTopStyle: `solid`,
        borderRadius: `lg`,
        boxSizing: `content-box`
    }
}

const TestimonialsBlock02 = ({content: {text, collection}}) => (
    <Container>
        <Box sx={{textAlign: `center`}}>
            <ContentText content={text}/>
        </Box>
        <Divider/>
        <Flex sx={{mx: -3, flexWrap: `wrap`}}>
            {collection?.map(({container, avatar, text, buttons}, index) => (
                <Box
                    key={`item-${index}`}
                    sx={{flexBasis: [`1`], flexGrow: 1, p: 3}}
                >
                    <Reveal effect='fadeInLeft'>
                        <Flex
                            sx={{
                                minHeight: "250px",
                                alignItems: `center`,
                                flexDirection: [`column`, null, null, index % 2 === 0 ? `row` : 'row-reverse']
                            }}
                        >
                            <ContentContainer
                                content={container}
                                variant='cards.paper'
                                sx={{
                                    flexBasis: `1/3`,
                                    textAlign: `center`,
                                    position: `relative`,
                                    width: `full`
                                }}
                            >
                                <ContentText content={text?.slice(0, 2)}/>
                            </ContentContainer>
                            <Box
                                sx={{
                                    ml: [0, null, null, index % 2 === 0 ? -3 : 3],
                                    mr: [0, null, null, index % 2 === 0 ? 3 : -3],
                                    mt: [-4, null, null, 0],
                                    mb: [3, null, null, 0]
                                }}
                            >
                                <ContentImages
                                    content={{images: [avatar]}}
                                    sx={styles.avatar}
                                    imageEffect='fadeIn'
                                />
                            </Box>

                            <Box sx={{
                                flexBasis: `1/3`,
                                flexGrow: 1,
                                padding: '10px',
                                textAlign: index % 2 === 0 ? 'left' : 'right'
                            }}>
                                <ContentText content={text?.slice(2)}/>
                                {buttons && (
                                    <Box sx={{textAlign: index % 2 === 0 ? 'left' : 'right', marginTop: '15px'}}>
                                        <ContentButtons content={buttons}/>
                                    </Box>
                                )}
                            </Box>

                        </Flex>
                    </Reveal>
                </Box>
            ))}
        </Flex>
    </Container>
)

export default WithDefaultContent(TestimonialsBlock02)
